import React from 'react';
import './App.css';
import './output.css';
import './index.css';
import type {ReactNode} from "react"

interface UserPageProps {
    children: ReactNode
}

const userBooks = [
    {
        bookName: "Moje kniha 1",
        bookEmoji: "📕"
    },
    {
        bookName: "Moje kniha 2",
        bookEmoji: "📕"
    },
    {
        bookName: "Moje kniha 2",
        bookEmoji: "📕"
    }
]

//TODO: Make tailwind work
//why the fuck tailwind works on vercel tf
interface BioProps {
    name: string,
    age: number,
    bookCount: number,
    level: number
}

function Bio(props: BioProps) {
    return (
        <div className={"flex flex-col p-10 border-2 rounded-xl border-black m-5"}>
            <h1>Jméno: {props.name}</h1>
            <ul>
                <li>Věk: {props.age}</li>
                <li>Moje knihy: {props.bookCount}</li>
                <li>Level: {props.level}</li>
            </ul>
        </div>
    );
}

interface BookProps {
    bookName: string,
    bookEmoji: string
}

const Book = (props: BookProps) => { //asi jsem nepochopil zadání s tou mapou, tak v interfacu prijimam pole objectu
    return (
        <div className={"flex flex-row p-5"}>
            <p>{props.bookName}</p>
            <p className={"text-xl"}>{props.bookEmoji}</p>
        </div>
    )
}

const UserPage = (props: UserPageProps) => (
    <div className={"flex"}>
        {props.children}
    </div>
);

export default function App() {
    return (
        <UserPage>
            <Bio name={"Petr Rychlý"} age={18} bookCount={4} level={3}/>
            <div className="flex flex-col border-2 rounded-xl border-black m-10">
                {userBooks.map((book) => <Book bookName={book.bookName} bookEmoji={book.bookEmoji}/>)}
            </div>
        </UserPage>

    );
}
